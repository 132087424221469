export enum OverlayKey {
  paywall = 'paywall',
  search = 'search',
  lisa_volg = 'lisa-volg',
  lisa_volg_paywall = 'lisa-volg-paywall',
  lisa_volg_tingimused = 'lisa-volg-tingimused',
  company_report_extra_download = 'company-report-extra-download',
  marketing_list_extra_download = 'marketing-list-extra-download',
  not_logged_in_paywall = 'not-logged-in-paywall',
  request_offer = 'soovin-pakkumist',
  finance_filter = 'finantsraport-filter',
  not_logged_in_debt_paywall = 'not-logged-in-debt-paywall',
  not_logged_in_monitoring_list_paywall = 'not-logged-in-monitoring-list-paywall',
  limit_monitoring_list_paywall = 'limit-monitoring-list-paywall',
  limit_download_paywall = 'limit-download-paywall',
  filter = 'filtreeri',
}
