'use client';

import * as React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export interface ILayoutSplitProps {
  children: React.ReactNode;
  layout?: 'default' | 'article' | 'profile' | 'filter' | 'certificates' | 'api' | 'report';
  className?: string;
}

const LayoutSplit: React.FunctionComponent<ILayoutSplitProps> = (props) => {
  return (
    <div
      className={classNames(
        styles['layout-split'],
        {
          [styles['layout-default']]: props.layout === 'default' || !props.layout,
          [styles['layout-article']]: props.layout === 'article',
          [styles['layout-profile']]: props.layout === 'profile',
          [styles['layout-filter']]: props.layout === 'filter',
          [styles['layout-certificates']]: props.layout === 'certificates',
          [styles['layout-api']]: props.layout === 'api',
          [styles['layout-report']]: props.layout === 'report',
        },
        props.className,
      )}
    >
      {props.children}
    </div>
  );
};

LayoutSplit.displayName = 'LayoutSplit';

export default LayoutSplit;
