import { IMarketingListToolFilter } from '@/components/content/MarketingListTool/interfaces/marketingListToolFilter.interface';

export const useTranslateKeysToEstonian = (form?: IMarketingListToolFilter): Record<string, unknown> => {
  const keyTranslations: Record<string, string> = {
    roles: 'Seos ettevõttega',
    tradingLines: 'Vastutusvaldkond',
    tops: 'Kuuluvus Äripäeva edetabelitesse',
    companyEmtaks: 'Tegevusala',
    companyEmployee: 'Töötajate arv',
    companyLocations: 'Asukoht',
    companySalesRevenue: 'Müügitulu',
    companyProfitLoss: 'Ärikasum / kahjum',
    companyLegalForms: 'Ettevõtte õiguslik vorm',
    companyTops: 'Kuuluvus Äripäeva edetabelitesse',
    companyEstablishmentDate: 'Asutamise aeg',
  };

  if (!form) {
    return {};
  }

  const translatedForm: Record<string, unknown> = {};
  for (const [key, value] of Object.entries(form)) {
    const translatedKey = keyTranslations[key] || key;
    translatedForm[translatedKey] = value;
  }

  return translatedForm;
};
