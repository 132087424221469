'use client';

import * as React from 'react';
import classNames from 'classnames';
import Tabs, { ITabsProps } from '@/components/ui/Tabs';
import Animations, { IAnimationsProps } from '@/components/ui/Animations';
import styles from './styles.module.scss';

export interface IWellProps {
  children: React.ReactNode;
  theme?: 'default' | 'lightest' | 'light' | 'medium' | 'dark';
  className?: string;
  title?: React.ReactNode;
  tabs?: ITabsProps['items'];
  animationLeft?: IAnimationsProps['animation'];
  animationRight?: IAnimationsProps['animation'];
}

const Well: React.FunctionComponent<IWellProps> = (props) => {
  return (
    <div
      className={classNames(
        styles['well'],
        {
          [styles['theme-default']]: props.theme === 'default' || !props.theme,
          [styles['theme-lightest']]: props.theme === 'lightest',
          [styles['theme-light']]: props.theme === 'light',
          [styles['theme-medium']]: props.theme === 'medium',
          [styles['theme-dark']]: props.theme === 'dark',

          [styles['has-animation']]: !!props.animationLeft || !!props.animationRight,
        },
        props.className,
      )}
    >
      {props.tabs && (
        <Tabs
          items={props.tabs}
          theme="well"
        />
      )}
      <div className={styles['well__wrapper']}>
        {props.animationLeft && (
          <div className={styles['well__animation-left']}>
            <Animations animation={props.animationLeft} />
          </div>
        )}
        {props.title && <h2 className={styles['well__title']}>{props.title}</h2>}
        <div className={styles['well__content']}>{props.children}</div>
        {props.animationRight && (
          <div className={styles['well__animation-right']}>
            <Animations animation={props.animationRight} />
          </div>
        )}
      </div>
    </div>
  );
};

Well.displayName = 'Well';

export default Well;
