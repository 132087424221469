'use client';

import * as React from 'react';
import classNames from 'classnames';
import LoadingContent from '../LoadingContent';
import Icon, { IconsAvailable } from '../Icon';
import styles from './styles.module.scss';

export interface IControlInputProps {
  /** Input type */
  type?: React.HTMLInputTypeAttribute;
  /** placeholder */
  placeholder?: string;
  /** Controlled value */
  value?: string;
  /** Default value */
  defaultValue?: string;
  /** Disabled status */
  disabled?: boolean;
  /** Readonly status */
  readonly?: boolean;
  /** Focus handler */
  onFocus?: React.DOMAttributes<HTMLInputElement>['onFocus'];
  /** Blur handler */
  onBlur?: React.DOMAttributes<HTMLInputElement>['onBlur'];
  /** Change handler */
  onChange?: (e: { target: { value: string } }) => void;
  /** Keydown handler */
  onKeyDown?: React.InputHTMLAttributes<HTMLInputElement>['onKeyDown'];
  /** Keyup handler */
  onKeyUp?: React.InputHTMLAttributes<HTMLInputElement>['onKeyUp'];
  /** Keyup handler */
  onClick?: React.InputHTMLAttributes<HTMLInputElement>['onClick'];
  /** Focus field on mount */
  autoFocus?: boolean;
  /** Addon element to append to field */
  addOn?: React.ReactNode;
  /** Addon element to prepend to field */
  addOnFront?: React.ReactNode;
  /** Color higlight for errors */
  hasError?: boolean;
  /** Autocomplete flag */
  autocomplete?: boolean;
  /** className to append */
  className?: string;
  /** Pass input ref */
  inputRef?: React.RefCallback<HTMLInputElement>;
  /** Styles to apply to wrapper */
  style?: React.CSSProperties;
  /** Styles to apply to inner input field */
  inputStyle?: React.CSSProperties;
  /** Max character count */
  maxLength?: number;
  /** DOM target element name attribute */
  name?: string;
  /** DOM target element id attribute */
  id?: string;
  /** Loading */
  loading?: boolean;
  /** Icon to display in front of everything */
  icon?: IconsAvailable;
  /** Title when used without associated label */
  title?: string;
  /** Dropdown menu to display */
  menu?: {
    id: string;
    label: string;
    icon?: IconsAvailable;
    onClick: () => void;
  }[];
}

/**
 * Regular HTML input field with extended functionality and styling. Use this within form row.
 */
const ControlInput: React.FunctionComponent<IControlInputProps> = (props) => {
  const [hasFocus, setHasFocus] = React.useState(false);
  const [dropOpen, setDropOpen] = React.useState(false);
  const [selectedMenuItemIndex, setsSelectedMenuItemIndex] = React.useState(-1);
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const dropRef = React.useRef<HTMLUListElement>(null);
  const activeItemRef: React.MutableRefObject<HTMLLIElement | null> = React.useRef(null);

  React.useEffect(() => {
    if (hasFocus) {
      setDropOpen(true);
      setsSelectedMenuItemIndex(-1);
    }
  }, [hasFocus]);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target;
      if (dropOpen) {
        if (target && wrapperRef.current && !wrapperRef.current.contains(target as Node)) {
          setDropOpen(false);
        }
      }
    };
    globalThis.addEventListener('mousedown', handleClickOutside);
    return () => {
      globalThis.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropOpen, wrapperRef, dropRef]);

  React.useEffect(() => {
    if (activeItemRef.current) {
      activeItemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [selectedMenuItemIndex]);

  return (
    <div
      className={classNames(
        styles['control-input'],
        {
          [styles['has-error']]: props.hasError,
          [styles['has-value']]: props.value && props.value.length > 0,
          [styles['focus']]: hasFocus,
          [styles['disabled']]: props.disabled,
          [styles['readonly']]: props.readonly,
        },
        props.className,
      )}
      style={props.style}
      onClick={(e) => {
        e.preventDefault();
        if (inputRef.current && !hasFocus) {
          inputRef.current.focus();
        }
      }}
      role="button"
      ref={wrapperRef}
    >
      {props.icon && (
        <Icon
          width={24}
          height={24}
          kind={props.icon}
          wrapperClassName={styles['control-input__icon']}
        />
      )}
      {props.addOnFront && <span className={styles['control-input__addon-front']}>{props.addOnFront}</span>}
      <div className={styles['control-input__holder']}>
        <input
          style={props.inputStyle}
          maxLength={props.maxLength}
          className={styles['control-input__input']}
          type={props.type}
          value={props.value}
          defaultValue={props.defaultValue}
          disabled={props.disabled}
          readOnly={props.readonly}
          onFocus={(e) => {
            setHasFocus(true);
            if (props.onFocus) {
              props.onFocus(e);
            }
          }}
          onBlur={(e) => {
            const target = e.relatedTarget;
            if (
              !(
                props.menu &&
                props.menu.length > 0 &&
                dropOpen &&
                target &&
                wrapperRef.current &&
                wrapperRef.current.contains(target as Node)
              )
            ) {
              setHasFocus(false);
              setDropOpen(false);
            }
            if (props.onBlur) {
              props.onBlur(e);
            }
          }}
          onChange={props.onChange}
          onKeyDown={(e) => {
            if (props.onKeyDown) {
              props.onKeyDown(e);
            } else {
              if (props.menu && props.menu.length > 0) {
                if (e.key === 'Escape') {
                  setDropOpen(false);
                  setsSelectedMenuItemIndex(-1);
                }
                if (e.key === 'ArrowDown' && !dropOpen) {
                  setDropOpen(true);
                }
                if (e.key === 'ArrowDown' && dropOpen) {
                  let newIndex = selectedMenuItemIndex;
                  if (props.menu.length <= selectedMenuItemIndex + 1) {
                    newIndex = 0;
                  } else {
                    newIndex = selectedMenuItemIndex + 1;
                  }
                  setsSelectedMenuItemIndex(newIndex);
                }
                if (e.key === 'ArrowUp' && !dropOpen) {
                  setDropOpen(true);
                }
                if (e.key === 'ArrowUp' && dropOpen) {
                  let newIndex = selectedMenuItemIndex;
                  if (selectedMenuItemIndex - 1 < 0) {
                    newIndex = props.menu.length - 1;
                  } else {
                    newIndex = selectedMenuItemIndex - 1;
                  }
                  setsSelectedMenuItemIndex(newIndex);
                }
                if (e.key === 'Enter' && dropOpen && selectedMenuItemIndex) {
                  props.menu[selectedMenuItemIndex].onClick();
                }
              }
            }
          }}
          onKeyUp={(e) => {
            if (props.onKeyUp) {
              props.onKeyUp(e);
            }
          }}
          onClick={props.onClick}
          placeholder={props.placeholder}
          spellCheck={false}
          ref={(e) => {
            if (props.inputRef) {
              props.inputRef(e);
            }
            if (e) {
              inputRef.current = e;
              if (props.autoFocus) {
                window.requestAnimationFrame(() => {
                  e.focus();
                });
              }
            }
          }}
          autoComplete={props.autocomplete ? 'on' : 'nope'} // Intentionally wrong to make it work in Chrome
          name={props.name}
          id={props.id}
          title={props.title}
          aria-busy={props.loading}
        />
      </div>
      {props.loading && (
        <span className={styles['control-input__loader']}>
          <LoadingContent size="tiny" />
        </span>
      )}
      {props.addOn && <span className={styles['control-input__addon']}>{props.addOn}</span>}
      {typeof props.menu !== 'undefined' && props.menu.length > 0 && (
        <ul
          ref={dropRef}
          {...(dropOpen ? {} : { inert: '' })} // https://github.com/facebook/react/issues/17157
          className={classNames(styles['control-input__drop'], { [styles['open']]: dropOpen })}
          onClick={() => {
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }}
        >
          {props.menu.map((item, itemIndex) => (
            <li
              key={item.label}
              ref={(elem) => {
                if (elem && itemIndex === selectedMenuItemIndex) {
                  activeItemRef.current = elem;
                }
              }}
            >
              <button
                role="button"
                onClick={item.onClick}
                className={classNames({
                  [styles['selected']]: selectedMenuItemIndex === itemIndex,
                })}
                tabIndex={-1}
              >
                <div className={styles['control-input__drop__inner']}>
                  <div className={styles['control-input__drop__label']}>{item.label}</div>
                  {item.icon && (
                    <Icon
                      width={20}
                      height={20}
                      kind={item.icon}
                    />
                  )}
                </div>
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

ControlInput.displayName = 'ControlInput';

export default ControlInput;
