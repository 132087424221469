'use client';

import * as React from 'react';
import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import LoadingContent from '../LoadingContent';
import Icon, { IconsAvailable, IIconProps } from '../Icon';
import styles from './styles.module.scss';

export interface IControlTextareaProps {
  /** placeholder */
  placeholder?: string;
  /** Controlled value */
  value: string;
  /** Default value */
  defaultValue?: string;
  /** Disabled status */
  disabled?: boolean;
  /** Readonly status */
  readonly?: boolean;
  /** Focus handler */
  onFocus?: React.TextareaHTMLAttributes<HTMLTextAreaElement>['onFocus'];
  /** Blur handler */
  onBlur?: React.TextareaHTMLAttributes<HTMLTextAreaElement>['onBlur'];
  /** Change handler */
  onChange?: React.TextareaHTMLAttributes<HTMLTextAreaElement>['onChange'];
  /** Keydown handler */
  onKeyDown?: React.TextareaHTMLAttributes<HTMLTextAreaElement>['onKeyDown'];
  /** Keyup handler */
  onKeyUp?: React.TextareaHTMLAttributes<HTMLTextAreaElement>['onKeyUp'];
  /** Keyup handler */
  onClick?: React.TextareaHTMLAttributes<HTMLTextAreaElement>['onClick'];
  /** Color higlight for errors */
  hasError?: boolean | '' | undefined;
  /** className to append */
  className?: string;
  /** Styles to apply to wrapper */
  style?: React.CSSProperties;
  /** Styles to apply to inner input field */
  inputStyle?: Omit<React.CSSProperties, 'maxHeight' | 'minHeight'> & {
    height?: number;
  };
  /** Max rows count */
  maxRows?: number;
  /** Min rows count */
  minRows?: number;
  /** DOM target element name attribute */
  name?: string;
  /** DOM target element id attribute */
  id?: string;
  /** Addon element to append to field */
  addOn?: React.ReactNode;
  /** Loading */
  loading?: boolean;
  /** Icon to display in front of everything */
  icon?: IconsAvailable;
  /** Icon intent */
  iconIntent?: IIconProps['intent'];
}

/**
 * Autogrowing textarea field with extended functionality and styling.
 */
const ControlTextarea: React.FunctionComponent<IControlTextareaProps> = (props) => {
  const [hasFocus, setHasFocus] = React.useState(false);

  return (
    <div
      className={classNames(
        styles['control-textarea'],
        {
          [styles['has-error']]: props.hasError,
          [styles['has-value']]: props.value.length > 0,
          [styles['focus']]: hasFocus,
          [styles['has-placeholder']]: props.placeholder,
          [styles['has-addon']]: props.addOn || props.loading,
          [styles['disabled']]: props.disabled,
        },
        props.className,
      )}
      style={props.style}
    >
      {props.icon && (
        <Icon
          width={16}
          height={16}
          kind={props.icon}
          wrapperClassName={styles['control-textarea__icon']}
          intent={props.iconIntent}
        />
      )}
      <div className={styles['control-textarea__holder']}>
        <TextareaAutosize
          style={props.inputStyle}
          className={styles['control-textarea__textarea']}
          value={props.value}
          defaultValue={props.defaultValue}
          disabled={props.disabled}
          readOnly={props.readonly}
          onFocus={(e) => {
            setHasFocus(true);
            if (props.onFocus) {
              props.onFocus(e);
            }
          }}
          onBlur={(e) => {
            setHasFocus(false);
            if (props.onBlur) {
              props.onBlur(e);
            }
          }}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
          onKeyUp={props.onKeyUp}
          onClick={props.onClick}
          placeholder={props.placeholder}
          spellCheck={false}
          name={props.name}
          id={props.id}
          rows={1}
          minRows={props.minRows}
          maxRows={props.maxRows}
          aria-busy={props.loading}
        />
      </div>
      {(props.addOn || props.loading) && (
        <span className={styles['control-textarea__addon']}>{props.loading ? <LoadingContent size="tiny" /> : props.addOn}</span>
      )}
    </div>
  );
};

ControlTextarea.displayName = 'ControlTextarea';

export default ControlTextarea;
