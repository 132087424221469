'use client';

import * as React from 'react';
import classNames from 'classnames';
import LoadingContent from '../LoadingContent';
import Icon, { IconsAvailable } from '../Icon';
import styles from './styles.module.scss';

export interface IControlSelectProps {
  /** Options to render */
  options: {
    label: string;
    value: string;
  }[];
  /** placeholder */
  placeholder?: string;
  /** Size */
  size?: 'default' | 'small';
  /** Controlled value */
  value?: string;
  /** Disabled status */
  disabled?: boolean;
  /** Focus handler */
  onFocus?: React.DOMAttributes<HTMLSelectElement>['onFocus'];
  /** Blur handler */
  onBlur?: React.DOMAttributes<HTMLSelectElement>['onBlur'];
  /** Change handler */
  onChange?: (e: { target: { value: string } }) => void;
  /** Keydown handler */
  onKeyDown?: React.InputHTMLAttributes<HTMLSelectElement>['onKeyDown'];
  /** Keyup handler */
  onKeyUp?: React.InputHTMLAttributes<HTMLSelectElement>['onKeyUp'];
  /** Keyup handler */
  onClick?: React.InputHTMLAttributes<HTMLSelectElement>['onClick'];
  /** Focus field on mount */
  autoFocus?: boolean;
  /** Addon element to prepend to field */
  addOn?: React.ReactNode;
  /** Color higlight for errors */
  hasError?: boolean;
  /** className to append */
  className?: string;
  /** Pass input ref */
  selectRef?: React.RefCallback<HTMLSelectElement>;
  /** Styles to apply to wrapper */
  style?: React.CSSProperties;
  /** Styles to apply to inner input field */
  selectStyle?: React.CSSProperties;
  /** DOM target element name attribute */
  name?: string;
  /** DOM target element id attribute */
  id?: string;
  /** Loading */
  loading?: boolean;
  /** Icon to display in front of everything */
  icon?: IconsAvailable;
  /** Title when used without associated label */
  title?: string;
}

/**
 * Regular HTML input field with extended functionality and styling. Use this within form row.
 */
const ControlSelect: React.FunctionComponent<IControlSelectProps> = (props) => {
  const [hasFocus, setHasFocus] = React.useState(false);
  const inputRef = React.useRef<HTMLSelectElement | null>(null);

  return (
    <div
      className={classNames(
        styles['control-select'],
        {
          [styles['has-error']]: props.hasError,
          [styles['has-value']]: props.value && props.value.length > 0,
          [styles['focus']]: hasFocus,
          [styles['disabled']]: props.disabled,
          [styles['size-default']]: props.size === 'default' || !props.size,
          [styles['size-small']]: props.size === 'small',
        },
        props.className,
      )}
      style={props.style}
      onClick={(e) => {
        e.preventDefault();
        if (inputRef.current && !hasFocus) {
          inputRef.current.focus();
        }
      }}
      role="button"
    >
      {props.icon && (
        <Icon
          width={24}
          height={24}
          kind={props.icon}
          wrapperClassName={styles['control-select__icon']}
        />
      )}
      {props.addOn && <span className={styles['control-select__addon']}>{props.addOn}</span>}
      <div className={styles['control-select__holder']}>
        <select
          style={props.selectStyle}
          className={styles['control-select__element']}
          value={props.value || ''}
          disabled={props.disabled}
          onFocus={(e) => {
            setHasFocus(true);
            if (props.onFocus) {
              props.onFocus(e);
            }
          }}
          onBlur={(e) => {
            setHasFocus(false);
            if (props.onBlur) {
              props.onBlur(e);
            }
          }}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
          onKeyUp={props.onKeyUp}
          onClick={props.onClick}
          // placeholder={props.placeholder} todo :: @Allan
          spellCheck={false}
          ref={(e) => {
            if (props.selectRef) {
              props.selectRef(e);
            }
            if (e) {
              inputRef.current = e;
              if (props.autoFocus) {
                window.requestAnimationFrame(() => {
                  e.focus();
                });
              }
            }
          }}
          name={props.name}
          id={props.id}
          title={props.title}
          aria-busy={props.loading}
        >
          {props.options.map((option, optionIndex) => (
            <option
              {...option}
              key={optionIndex}
            >
              {}
            </option>
          ))}
        </select>
      </div>
      {props.loading && (
        <span className={styles['control-select__loader']}>
          <LoadingContent size="tiny" />
        </span>
      )}
      <Icon
        width={20}
        height={20}
        kind="arrow03-down"
        wrapperClassName={styles['control-select__arrow']}
      />
    </div>
  );
};

ControlSelect.displayName = 'ControlSelect';

export default ControlSelect;
