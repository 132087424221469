'use client';

import * as React from 'react';
import { ButtonChildren } from '../index';

export interface IButtonsPrimaryProps {
  children?: ButtonChildren | ButtonChildren[];
}

/**
 * Primary button group to use within Buttons component. Rendered in prime position.
 */
const ButtonsPrimary: React.FunctionComponent<IButtonsPrimaryProps> = (props) => {
  if (React.Children.count(props.children) === 0) {
    return null;
  } else {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
};

ButtonsPrimary.displayName = 'ButtonsPrimary';

export default ButtonsPrimary;
