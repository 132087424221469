import * as React from 'react';
import classNames from 'classnames';
import Button, { IButtonProps } from '@/components/ui/Button';
import Icon, { IconsAvailable } from '@/components/ui/Icon';
import Buttons from '@/components/ui/Buttons';
import styles from './styles.module.scss';

export interface ICalloutProps {
  text?: string;
  intent?: 'default' | 'warning' | 'danger' | 'success' | 'discrete';
  buttons?: IButtonProps[];
  icon?: IconsAvailable;
}

const Callout: React.FunctionComponent<ICalloutProps> = (props) => {
  return (
    <div
      className={classNames(styles['callout'], {
        [styles['intent-default']]: props.intent === 'default' || !props.intent,
        [styles['intent-danger']]: props.intent === 'danger',
        [styles['intent-warning']]: props.intent === 'warning',
        [styles['intent-success']]: props.intent === 'success',
        [styles['intent-discrete']]: props.intent === 'discrete',
      })}
    >
      <div className={styles['callout__inner']}>
        {typeof props.icon !== 'undefined' && (
          <Icon
            width={40}
            height={40}
            kind={props.icon}
            className={styles['callout__icon']}
          />
        )}
        <div className={styles['callout__text']}>
          <div>{props.text}</div>
        </div>
        {props.buttons && props.buttons.length > 0 && (
          <Buttons size={props.intent !== 'discrete' ? 'small' : 'default'}>
            {props.buttons.map((b, bi) => (
              <Button
                key={bi}
                size={props.intent !== 'discrete' ? 'small' : 'default'}
                {...b}
              />
            ))}
          </Buttons>
        )}
      </div>
    </div>
  );
};

Callout.displayName = 'Callout';

export default Callout;
