'use client';

import * as React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export interface IFormRowProps {
  label?: React.ReactNode;
  for?: string;
  value?: React.ReactNode;
  children?: React.ReactNode;
  description?: React.ReactNode;
  size?: 'default' | 'narrow';
  required?: boolean | string;
  hasError?: boolean | string;
}

const FormRow: React.FunctionComponent<IFormRowProps> = (props) => {
  return (
    <div
      className={classNames(styles['form-row'], {
        [styles['size-default']]: props.size === 'default' || !props.size,
        [styles['size-narrow']]: props.size === 'narrow',
        [styles['has-error']]: !!props.hasError,
      })}
    >
      {props.label && (
        <div className={styles['form-row__label']}>
          {props.label && <label htmlFor={props.for}>{props.label}</label>}
          {props.required && (
            <span className={styles['form-row__label__required']}>{typeof props.required === 'boolean' ? '' : props.required} *</span>
          )}
        </div>
      )}
      {props.children && (
        <div className={styles['form-row__content']}>
          <div className={styles['form-row__content__items']}>{props.children}</div>
          {props.description && <div className={styles['form-row__content__description']}>{props.description}</div>}
          {typeof props.hasError === 'string' && <div className={styles['form-row__content__error']}>{props.hasError}</div>}
        </div>
      )}
      {props.value && <div className={styles['form-row__value']}>{props.value}</div>}
    </div>
  );
};

FormRow.displayName = 'FormRow';

export default FormRow;
